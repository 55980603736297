import React, { useState, useRef, useEffect } from "react"
import { Link as GatsbyLink } from "gatsby"
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap"
import { StaticImage } from "gatsby-plugin-image"

const Menu = ({ handleClick }) => {
  const [isShowItem, setShowItem] = useState(false)
  const toggleAboutMenu = () => {
    setShowItem(!isShowItem)
  }
  const [isOpenItem, setOpenItem] = useState(false)
  const toggleCourseMenu = () => {
    setOpenItem(!isOpenItem)
  }
  const [isOpennItem, setOpennItem] = useState(false)
  const toggleFundingMenu = () => {
    setOpennItem(!isOpennItem)
  }

  return (
    <ul className="menu">
      <li className="menuItem">
        <GatsbyLink
          to="/"
          className="menuLink"
          activeClassName="active"
          onClick={handleClick}
        >
          Home
        </GatsbyLink>
      </li>
      <li className="menuItem">
        <Dropdown
          isOpen={isOpennItem}
          toggle={toggleFundingMenu}
        >
          <DropdownToggle
            className="menuLink"
            nav
            aria-hidden="true"
          >
            Funding{" "}
            {isOpennItem === true ? (
              <StaticImage
                className="vectorImg"
                src="../../assets/images/VectorUp.png"
                alt="VectorUp"
              />
            ) : (
              <StaticImage
                className="vectorImg"
                src="../../assets/images/Vector.png"
                alt="Vector"
              />
            )}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem>
              <GatsbyLink
                to="/funding"
                className="menuLink"
                activeClassName="active"
                onClick={handleClick}
              >
                SSG Funding
              </GatsbyLink>
            </DropdownItem>
            <DropdownItem>
              <GatsbyLink
                to="/PSEA"
                className="menuLink"
                activeClassName="active"
                onClick={handleClick}
              >
                PSEA Scheme
              </GatsbyLink>
            </DropdownItem>
            <DropdownItem>
              <GatsbyLink
                to="/SFC"
                className="menuLink"
                activeClassName="active"
                onClick={handleClick}
              >
                SkillsFuture Credit
              </GatsbyLink>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </li>
      <li className="menuItem">
        <Dropdown
          isOpen={isShowItem}
          toggle={toggleAboutMenu}
        >
          <DropdownToggle
            className="menuLink"
            nav
            aria-hidden="true"
          >
            About{" "}
            {isShowItem === true ? (
              <StaticImage
                className="vectorImg"
                src="../../assets/images/VectorUp.png"
                alt="VectorUp"
              />
            ) : (
              <StaticImage
                className="vectorImg"
                src="../../assets/images/Vector.png"
                alt="Vector"
              />
            )}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem>
              <GatsbyLink
                to="/aboutaia"
                className="menuLink"
                activeClassName="active"
                onClick={handleClick}
              >
                About AIA
              </GatsbyLink>
            </DropdownItem>
            <DropdownItem>
              <GatsbyLink
                to="/whyaia"
                className="menuLink"
                activeClassName="active"
                onClick={handleClick}
              >
                Why AIA
              </GatsbyLink>
            </DropdownItem>
            <DropdownItem>
              <GatsbyLink
                to="/theteam"
                className="menuLink"
                activeClassName="active"
                onClick={handleClick}
              >
                Meet The Team
              </GatsbyLink>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </li>
      <li className="menuItem">
        <Dropdown
          isOpen={isOpenItem}
          toggle={toggleCourseMenu}
        >
          <DropdownToggle className="menuLink" nav>
            All Courses
            {isOpenItem === true ? (
              <StaticImage
                className="vectorImg"
                src="../../assets/images/VectorUp.png"
                alt="VectorUp"
              />
            ) : (
              <StaticImage
                className="vectorImg"
                src="../../assets/images/Vector.png"
                alt="Vector"
              />
            )}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem>
              <GatsbyLink
                to="/courses-ifc"
                className="menuLink"
                activeClassName="active"
                onClick={handleClick}
              >
                IFC Courses
              </GatsbyLink>
            </DropdownItem>
            <DropdownItem>
              <GatsbyLink
                to="/courses-autodesk"
                className="menuLink"
                activeClassName="active"
                onClick={handleClick}
              >
                Autodesk Courses
              </GatsbyLink>
            </DropdownItem>
            <DropdownItem>
              <GatsbyLink
                to="/courses-bentley"
                className="menuLink"
                activeClassName="active"
                onClick={handleClick}
              >
                Bentley Courses
              </GatsbyLink>
            </DropdownItem>
            <DropdownItem>
              <GatsbyLink
                to="/courses-bim"
                className="menuLink"
                activeClassName="active"
                onClick={handleClick}
              >
                BIM Courses
              </GatsbyLink>
            </DropdownItem>
            <DropdownItem>
              <GatsbyLink
                to="/courses-skillsfuture"
                className="menuLink"
                activeClassName="active"
                onClick={handleClick}
              >
                SkillsFuture Courses
              </GatsbyLink>
            </DropdownItem>
            <DropdownItem>
              <GatsbyLink
                to="/short-courses"
                className="menuLink"
                activeClassName="active"
                onClick={handleClick}
              >
                1-Day Short Courses
              </GatsbyLink>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </li>
      <li className="menuItem">
        <GatsbyLink
          to="/forlearners"
          className="menuLink"
          activeClassName="active"
          onClick={handleClick}
        >
          For Learners
        </GatsbyLink>
      </li>
      <li className="menuItem">
        <GatsbyLink
          to="/contact"
          className="menuLink"
          activeClassName="active"
          onClick={handleClick}
        >
          Contact
        </GatsbyLink>

        {/* <script src="https://www.myvelox.com/widget/velox_widget.js"
          type="text/javascript"
          id="velox_widget"
          velox-access-id="XEm6Iw4H5u2lPdsdJZSu"
          velox-key="SeTEd911DMoQ94F5V1IZ23WSuIb7R9GEIpeaAT4I"
          velox-logo="https://www1.bca.gov.sg/images/default-source/corenet-x-ifc_sg/ais.png?sfvrsn=81040b0e_2"
          velox-description="ACE Group"
          _widget-mobile-position="right"
        ></script> */}
      </li>
    </ul>
  )
}

export default Menu
